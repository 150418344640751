const USER_TOKEN = 'token';

export const localStorageApi = {
    setToken(token: string): void {
        localStorage.setItem(USER_TOKEN, token);
    },

    getToken(): string | null {
        return localStorage.getItem(USER_TOKEN);
    },

    removeUserData(): void {
        localStorage.removeItem(USER_TOKEN);
    }
};
