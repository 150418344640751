import { lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuth } from 'entities/user';
import { AuthStatus, RouteName } from 'shared/model';
import { Loader } from 'shared/ui';

import { authRoutes, publicRoutes } from './model';

import type { ReactNode } from 'react';

const Layout = lazy(() => import('pages/layout'));
const NotFound = lazy(() => import('pages/not-found'));

interface Props {
    children: ReactNode;
}

function ProtectedRoute({ children }: Props) {
    const { authStatus } = useAuth();
    return authStatus === AuthStatus.SignedIn ? (
        <>{children}</>
    ) : (
        <Navigate to={RouteName.LOGIN_PAGE} replace />
    );
}

export function AppRouter() {
    const { authChecked, authStatus } = useAuth();

    useEffect(() => {
        void authChecked();
    }, [authChecked]);

    return authStatus === AuthStatus.Loading ? (
        <Loader />
    ) : (
        <Routes>
            <Route path='/' element={<Layout />}>
                {publicRoutes.map(({ path, component: Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                ))}
                {authRoutes.map(({ path, component: Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute>
                                <Component />
                            </ProtectedRoute>
                        }
                    />
                ))}
                <Route path={RouteName.NOT_FOUND_PAGE} element={<NotFound />} />
            </Route>
        </Routes>
    );
}
