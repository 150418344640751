import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { Producer } from 'shared/model';

export const producerApi = createApi({
    baseQuery: fetchBaseQuery({
        method: 'GET',
        baseUrl: process.env.REACT_APP_API_URL
    }),
    reducerPath: 'producerApi',
    endpoints: build => ({
        getProducers: build.query<Producer[], void>({
            query: () => ({
                url: '/producers'
            })
        })
    })
});

export const { useGetProducersQuery } = producerApi;
