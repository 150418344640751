import { memo } from 'react';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    variant?: 'primary' | 'secondary';
    width?: 'max' | 'min' | 'auto';
    children: ReactNode;
}

export const DropdownToggle = memo(({ children }: Props) => (
    <div className='dropdown-toggle'>{children}</div>
));

export const DropdownMenu = memo(({ width = 'max', children }: Props) => (
    <div className={`dropdown-menu ${width}`}>
        <div>{children}</div>
    </div>
));

export const Dropdown = memo(({ variant = 'primary', children }: Props) => (
    <div className={`dropdown ${variant}`}>{children}</div>
));
