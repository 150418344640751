export interface Sort {
    id: number;
    name: string;
}

export const sorts = [
    { id: 1, name: 'А' },
    { id: 2, name: '↓' },
    { id: 3, name: '↑' }
];
