import { memo } from 'react';
import cn from 'classnames';

import type { ButtonHTMLAttributes, ReactNode } from 'react';

import './styles.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: 'small' | 'medium' | 'medium-large' | 'large' | 'extra-large';
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'quadruple'
        | 'primary-ghost'
        | 'secondary-ghost'
        | 'link';
    width?: 'auto' | 'max' | 'min';
    borderRadius?: 'primary' | 'secondary';
    whiteSpace?: 'nowrap' | 'normal';
    isActive?: boolean;
    display?: 'flex';
    children: ReactNode;
}

export const Button = memo(
    ({
        type,
        size = 'medium',
        variant = 'primary',
        width = 'auto',
        borderRadius = 'primary',
        whiteSpace,
        isActive,
        display,
        children,
        ...props
    }: Props) => {
        const buttonClass = cn(
            size,
            width,
            variant,
            `${borderRadius}-border-radius`,
            whiteSpace,
            display,
            {
                button: variant !== 'link',
                active: isActive
            }
        );
        return (
            <button type={type || 'button'} className={buttonClass} {...props}>
                {children}
            </button>
        );
    }
);
