import { createListenerMiddleware } from '@reduxjs/toolkit';
import { IoCloseOutline } from 'react-icons/io5';
import toast from 'react-hot-toast';

import { userSignedUp } from 'entities/user';

export const signedUpMiddleware = createListenerMiddleware();

signedUpMiddleware.startListening({
    actionCreator: userSignedUp,
    effect: () => {
        toast(t => (
            <div className='toast'>
                Было отправлено подтверждение на электронную указанную почту
                <button onClick={() => toast.dismiss(t.id)}>
                    <IoCloseOutline size={29} />
                </button>
            </div>
        ));
    }
});
