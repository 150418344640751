import megaLogoSVG from 'shared/ui/icon/assets/icons/mega-logo.svg';
import fwLogoLightSVG from 'shared/ui/icon/assets/icons/fw-logo-light.svg';
import fwLogoDarkSVG from 'shared/ui/icon/assets/icons/fw-logo-dark.svg';

import aboutUsServicesJPG from 'shared/ui/icon/assets/images/about-us-services.jpg';
import aboutUsFWJPG from 'shared/ui/icon/assets/images/about-us-fw.jpg';
import FwJPG from 'shared/ui/icon/assets/images/fw.jpg';
import fwGallery1JPG from 'shared/ui/icon/assets/images/fw-gallery-1.jpg';
import fwGallery2JPG from 'shared/ui/icon/assets/images/fw-gallery-2.jpg';
import jobFlowerJPG from 'shared/ui/icon/assets/images/job-flower.jpg';
import service1PNG from 'shared/ui/icon/assets/images/service1.png';
import service2PNG from 'shared/ui/icon/assets/images/service2.png';
import service3PNG from 'shared/ui/icon/assets/images/service3.png';
import service4PNG from 'shared/ui/icon/assets/images/service4.png';
import service5PNG from 'shared/ui/icon/assets/images/service5.png';
import errorGIF from 'shared/ui/icon/assets/images/404.gif';

export const JPG_PNG = {
    'about-us-services': aboutUsServicesJPG,
    'about-us-fw': aboutUsFWJPG,
    fw: FwJPG,
    'fw-gallery-1': fwGallery1JPG,
    'fw-gallery-2': fwGallery2JPG,
    'job-flower': jobFlowerJPG,
    'service-1': service1PNG,
    'service-2': service2PNG,
    'service-3': service3PNG,
    'service-4': service4PNG,
    'service-5': service5PNG,
    '404': errorGIF
};

export type JpgPngImageNames = keyof typeof JPG_PNG;

export const SVG = {
    'mega-logo': megaLogoSVG,
    'fw-logo-light': fwLogoLightSVG,
    'fw-logo-dark': fwLogoDarkSVG
};

export type SvgIconName = keyof typeof SVG;
