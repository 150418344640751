import cn from 'classnames';

import { DFlex } from '../d-flex';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    color?: 'primary' | 'secondary' | 'none';
    borderRadius?: 'primary' | 'secondary' | 'none';
    padding?:
        | 'extra-small'
        | 'small'
        | 'small-medium'
        | 'medium'
        | 'large'
        | 'large-extra-large'
        | 'extra-large';
    gap?:
        | 'extra-extra-small'
        | 'extra-small'
        | 'small'
        | 'medium'
        | 'large'
        | 'extra-large';
    direction?: 'column' | 'row';
    align?: 'center' | 'left' | 'right';
    justify?: 'space-between' | 'space-around';
    carousel?: 'overflow-x' | 'overflow-y';
    children: ReactNode;
}

export const Card = ({
    color = 'primary',
    borderRadius = 'primary',
    padding = 'small',
    gap = 'medium',
    direction,
    align,
    justify,
    carousel,
    children
}: Props) => {
    const cardClass = cn(
        'card',
        color,
        `${borderRadius}-border-radius`,
        `${padding}-padding`
    );
    return (
        <div className={cardClass}>
            <DFlex
                gap={gap}
                direction={direction}
                align={align}
                justify={justify}
                carousel={carousel}
            >
                {children}
            </DFlex>
        </div>
    );
};
