import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Loader } from 'shared/ui';

import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

export function RouterProvider({ children }: Props) {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>{children}</Suspense>
        </BrowserRouter>
    );
}
