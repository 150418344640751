import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { TypePropertyFull } from 'shared/model';

export const typePropertyApi = createApi({
    baseQuery: fetchBaseQuery({
        method: 'GET',
        baseUrl: process.env.REACT_APP_API_URL
    }),
    reducerPath: 'typePropertyApi',
    endpoints: build => ({
        getPropertyByTypeId: build.query<TypePropertyFull[], number>({
            query: typeId => ({
                url: '/type_properties',
                params: {
                    typeId: typeId
                }
            })
        })
    })
});

export const { useGetPropertyByTypeIdQuery } = typePropertyApi;
