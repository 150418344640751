import { memo } from 'react';
import cn from 'classnames';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    variant?: 'primary' | 'secondary';
    children?: ReactNode;
}

export const Container = memo(({ variant = 'primary', children }: Props) => {
    const containerClass = cn('container', `${variant}-background`);

    return (
        <div className={containerClass}>
            <div>{children}</div>
        </div>
    );
});
