export interface User {
    id: number | null;
    email: string;
    role: string;
}

export interface UserResponse {
    id: number;
    email: string;
    token: string;
    role: string;
    status: boolean;
}

export enum AuthStatus {
    Loading = 'Loading',
    SignedUp = 'SignedUp',
    SignedIn = 'SignedIn',
    SignedOut = 'SignedOut'
}

export enum AuthRole {
    User = 'USER',
    Admin = 'ADMIN'
}

export interface BasketRequest {
    productId: number;
    value: number;
}

export interface BasketResponse {
    id: number;
    productId: number;
    value: number;
}

export interface BasketItem {
    id: number;
    value: number;
    productId: number;
    name: string;
    price: number;
    img: string;
    desc: string;
    typeId: number;
}
