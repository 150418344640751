import { AppRouter } from './router';
import { RouterProvider, StoreProvider, ThemeProvider } from './providers';

import './styles/reset.scss';
import './styles/index.scss';

export function App() {
    return (
        <RouterProvider>
            <StoreProvider>
                <ThemeProvider>
                    <AppRouter />
                </ThemeProvider>
            </StoreProvider>
        </RouterProvider>
    );
}
