import { memo } from 'react';
import { Helmet } from 'react-helmet';

import type { HelmetType } from 'shared/lib';

interface Props {
    item: HelmetType;
}

export const TitleDescription = memo(({ item }: Props) => {
    const defaultTitle =
        'МегаСервис в Орле - товары для полиграфии и фоторамки, заправка картриджей, полиграфические услуги';
    const defaultDescription =
        'Широкий ассортимент товаров для чёрно-белой и цветной полиграфии и фоторамки, выгодные цены в каталоге МегаСервиса. В перечень услуг МегаСервиса входит заправка картрижей, ремонт техники и фопечать.';
    const defaultCanonical = process.env.REACT_APP_BASIC_URL;
    return (
        <Helmet>
            <title>{item.title ? item.title : defaultTitle}</title>
            <meta
                name='description'
                content={
                    item.description ? item.description : defaultDescription
                }
            />
            <link
                rel='canonical'
                href={item.canonical ? item.canonical : defaultCanonical}
            />
            <meta
                name='robots'
                content={item.canonical ? 'all' : 'noindex, nofollow'}
            />
        </Helmet>
    );
});
