import { memo } from 'react';
import cn from 'classnames';

import { JPG_PNG } from '../../model';

import type { ImgHTMLAttributes } from 'react';
import type { JpgPngImageNames } from '../../model';

import './styles.scss';

export interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    image?: JpgPngImageNames;
    isObjectFit?: boolean;
    borderRadius?: 'primary' | 'secondary';
    widthAndHeight?: number;
}

export const Image = memo(
    ({
        image,
        isObjectFit = true,
        borderRadius,
        width,
        height,
        src,
        widthAndHeight
    }: Props) => {
        const imageClass = cn('image', {
            'object-fit': isObjectFit,
            [`${borderRadius}-border-radius`]: borderRadius
        });
        return (
            <img
                className={imageClass}
                src={src || (image && JPG_PNG[image])}
                {...(widthAndHeight || width
                    ? { width: widthAndHeight || width }
                    : {})}
                {...(widthAndHeight || height
                    ? { height: widthAndHeight || height }
                    : {})}
                alt='Изображение'
            />
        );
    }
);
