import { useCallback, useEffect, useState } from 'react';

import { useAuth } from 'entities/user';
import { AuthStatus } from 'shared/model';

export const useBasket = () => {
    const { basket, authStatus, getBasket } = useAuth();
    const [isBasketLoading, setIsBasketLoading] = useState<boolean>(true);

    const loadingBasket = useCallback(async (): Promise<void> => {
        setIsBasketLoading(true);
        await getBasket();
        setIsBasketLoading(false);
    }, [getBasket]);

    useEffect(() => {
        if (authStatus === AuthStatus.SignedIn) {
            void loadingBasket();
        } else {
            setIsBasketLoading(false);
        }
    }, [authStatus, loadingBasket]);

    return { basket, isBasketLoading };
};
