import { createContext } from 'react';

import { Theme } from 'shared/model';

interface ThemeContextType {
    theme: Theme;
    setTheme: (theme: Theme) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
    theme: Theme.Light,
    setTheme: (theme: Theme) => {
        void theme;
    }
});
