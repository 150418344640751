import cn from 'classnames';

import type { ReactNode } from 'react';
import type { BackgroundType } from 'shared/model';

import './styles.scss';

interface Props {
    color?: BackgroundType;
    borderRadius?: 'primary' | 'secondary';
    children: ReactNode;
}

export const Background = ({
    color = 'light',
    borderRadius,
    children
}: Props) => {
    const backgroundClass = cn('background', color, {
        [`${borderRadius}-border-radius`]: borderRadius
    });
    return <div className={backgroundClass}>{children}</div>;
};
