export interface ContactType {
    text: string;
    phone: string;
    email: string;
    phoneHref: string;
}

export const shopContacts = {
    text: 'Магазин',
    phone: '+7 (962) 475-26-50',
    email: 'dommody@mega-orel.ru',
    phoneHref: 'tel:+79624752650'
};

export const framingWorkshopContacts = {
    text: 'Багетная мастерская',
    phone: '+7 (962) 475-26-50',
    email: 'print@mega-orel.ru',
    phoneHref: 'tel:+79624752450'
};

export const serviceCenterContacts = {
    text: 'Сервисный центр, 2 этаж',
    phone: '+7 (962) 475-22-50',
    email: 'service@mega-orel.ru',
    phoneHref: 'tel:+79624752250'
};

export const corporateDepartmentContacts = {
    text: 'Корпоративный отдел, 2 этаж',
    phone: '+7 (953) 618-85-57',
    email: 'info@mega-orel.ru',
    phoneHref: 'tel:+79536188557'
};
