import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { Filter } from 'shared/model';

export interface FilterState {
    filter: Filter[];
}

const initialState: FilterState = {
    filter: []
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        filterChanged(state, action: PayloadAction<Filter[]>) {
            state.filter = action.payload;
        }
    }
});

export const { filterChanged } = filterSlice.actions;

export const filterReducer = filterSlice.reducer;
