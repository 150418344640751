import type { ComponentType } from 'react';

export const RouteName = {
    MAIN_PAGE: '/',
    REGISTRATION_PAGE: '/signup',
    LOGIN_PAGE: '/signin',
    SHOP_PAGE: '/shop',
    PRODUCT_PAGE: '/product',
    SERVICES_PAGE: '/services',
    CONTACTS_PAGE: '/contacts',
    FRAMING_WORKSHOP_PAGE: '/framing-workshop',
    DELIVERY_PAYMENT_PAGE: '/delivery-and-payment',
    JOBS_PAGE: '/jobs',
    NEWS_PAGE: '/news',
    ADMIN_PAGE: '/admin',
    BASKET_PAGE: '/basket',
    NOT_FOUND_PAGE: '*'
};

export interface RouteDescription {
    path: string;
    component: ComponentType;
}
