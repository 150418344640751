import cn from 'classnames';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    children: ReactNode;
    rowGap?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
    columns?: '2' | '3' | '4';
    align?: 'center' | 'left' | 'right';
    size?: 'large' | 'medium';
}

export const Columns = ({
    children,
    columns = '3',
    align,
    rowGap,
    size = 'medium'
}: Props) => {
    const columnClass = cn('columns', align, size, `columns__${columns}`, {
        [`${rowGap}-row-gap`]: rowGap
    });
    return <div className={columnClass}>{children}</div>;
};
