import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { Product } from 'shared/model';

export const productsApi = createApi({
    baseQuery: fetchBaseQuery({
        method: 'GET',
        baseUrl: process.env.REACT_APP_API_URL
    }),
    reducerPath: 'productsApi',
    endpoints: build => ({
        getProducts: build.query<Product[], void>({
            query: () => ({
                url: '/products'
            })
        }),
        getProductByTypeId: build.query<Product[], number>({
            query: typeId => ({
                url: '/products',
                params: {
                    typeId: typeId
                }
            })
        }),
        getProductsByName: build.query<Product[], string>({
            query: name => ({
                url: '/products',
                params: {
                    name: name
                }
            })
        }),
        getProductById: build.query<Product, number>({
            query: id => ({
                url: `/products/${id}`
            })
        })
    })
});

export const {
    useGetProductsQuery,
    useGetProductByTypeIdQuery,
    useGetProductsByNameQuery,
    useGetProductByIdQuery
} = productsApi;
