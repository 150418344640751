import { memo } from 'react';
import cn from 'classnames';

import { NavLink } from 'react-router-dom';

import type { ReactNode } from 'react';
import type { NavLinkProps } from 'react-router-dom';

import './styles.scss';

interface Props extends NavLinkProps {
    variant?: 'action' | 'underline';
    children: ReactNode;
}

export const Link = memo(
    ({ variant = 'underline', children, ...props }: Props) => {
        const linkClass = cn('link', variant);
        return (
            <NavLink className={linkClass} {...props}>
                {children}
            </NavLink>
        );
    }
);
