import cn from 'classnames';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    gap?:
        | 'extra-extra-small'
        | 'extra-small'
        | 'small'
        | 'medium'
        | 'large'
        | 'extra-large';
    direction?: 'column' | 'row';
    align?: 'center' | 'left' | 'right';
    justify?: 'space-between' | 'space-around';
    carousel?: 'overflow-x' | 'overflow-y';
    withIcon?: boolean;
    children: ReactNode;
}

export const DFlex = ({
    gap = 'medium',
    direction = 'column',
    align,
    justify,
    carousel,
    withIcon,
    children
}: Props) => {
    const dFlexClass = cn(
        'd-flex',
        direction,
        align,
        justify,
        `${gap}-gap`,
        carousel,
        {
            carousel: carousel,
            icon: withIcon
        }
    );

    return <div className={dFlexClass}>{children}</div>;
};
