import { memo } from 'react';
import cn from 'classnames';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
    size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
    weight?: 'light' | 'regular' | 'medium' | 'bold' | 'extra-bold';
    color?:
        | 'secondary-action'
        | 'day-primary'
        | 'primary-action'
        | 'invert-action'
        | 'quadruple';
    align?: 'center' | 'left' | 'right';
    family?: 'primary' | 'secondary';
    transform?: 'uppercase';
    lineClamp?: 1 | 2;
    display?: 'flex';
    wrap?: 'pre-wrap' | 'nowrap';
    children: ReactNode;
}

export const Text = memo(
    ({
        tag,
        size = 's',
        weight = 'light',
        align = 'left',
        color,
        family = 'primary',
        transform,
        lineClamp,
        display,
        wrap = 'pre-wrap',
        children
    }: Props) => {
        const Element = tag || 'div';
        const textClass = cn(
            'text',
            size,
            weight,
            align,
            `font-${family}`,
            display,
            wrap,
            {
                [`text-${color}`]: color,
                [`transform-${transform}`]: transform,
                'line-clamp': lineClamp,
                [`line-clamp-${lineClamp}`]: lineClamp
            }
        );

        return <Element className={textClass}>{children}</Element>;
    }
);
