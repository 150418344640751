import { RouteName } from 'shared/model';

import type { Product, Ratio, Type, TypePropertyFull } from 'shared/model';

const isFramingWorkshop = (typeId: number): boolean => {
    return typeId === 5;
};

export const isAllProduct = (typeId: number): boolean => {
    return typeId === 1;
};

export const withColorWarningProduct = (typeId: number): boolean => {
    return typeId === 4;
};

export const getTypeName = (selectedType: Type | undefined): string =>
    selectedType?.name || 'Не найдено';

export const getProductRatio = (typeId: number): Ratio => {
    if (typeId === 4) {
        return 'rectangle-frame';
    } else if (typeId === 5) {
        return 'rectangle';
    } else {
        return 'square';
    }
};

export const getProductVariant = (path: string): 'tertiary' | 'quadruple' => {
    if (path !== 'shop') {
        return 'quadruple';
    } else {
        return 'tertiary';
    }
};

export const getProductLink = (typeId: number, path: string): string => {
    if (path !== 'shop') {
        return RouteName.FRAMING_WORKSHOP_PAGE + '/' + typeId;
    } else {
        return RouteName.SHOP_PAGE + '/' + typeId;
    }
};

export const filterTypeProperty = (
    property: TypePropertyFull,
    path: string
): boolean => {
    if (path !== 'shop') {
        return isFramingWorkshop(property.typeId);
    } else {
        return !isFramingWorkshop(property.typeId);
    }
};

export const filterProduct = (product: Product, path: string): boolean => {
    if (path !== 'shop') {
        return isFramingWorkshop(product.typeId);
    } else {
        return !isFramingWorkshop(product.typeId);
    }
};

export const getProductTypes = (types: Type[], path: string): Type[] => {
    if (path !== 'shop') {
        return types.filter(el => isFramingWorkshop(el.id));
    } else {
        return types.filter(el => !isFramingWorkshop(el.id));
    }
};

export const getProductSelectedType = (
    types: Type[],
    typeId: number,
    path: string
): Type | undefined => {
    if (path !== 'shop') {
        return types.find(el => el.id === typeId && isFramingWorkshop(el.id));
    } else {
        return types.find(el => el.id === typeId && !isFramingWorkshop(el.id));
    }
};

export interface HelmetType {
    title?: string;
    description?: string;
    canonical?: string;
}

export const getProductHelmet = (
    name: string,
    typeId: number,
    path: string
): HelmetType => {
    if (path !== 'shop') {
        return {
            title:
                name +
                ' в Орле - каталог товаров в ассортименте Багетной мастерской',
            description:
                name +
                ' и другие товары представлены в широкой цветовой гамме и по привлекательным ценам в Багетной мастерской Орла',
            canonical:
                process.env.REACT_APP_BASIC_URL +
                RouteName.FRAMING_WORKSHOP_PAGE +
                '/' +
                typeId
        };
    } else {
        return {
            title: isAllProduct(typeId)
                ? 'Магазин МегаСервис в Орле - купите товары для чёрно-белой и цветной полиграфии и фоторамки по низкой цене в интернет-магазине Орле'
                : name +
                  ' в Орле - купить по низким ценам в интернет-магазине МегаСервис',
            description: isAllProduct(typeId)
                ? 'В наш ассортимент входит спектр товаров для чёрно-белой и цветной полиграфии: фотобумага различных форматов и плотности, чернила для струйных принтеров, фоторамки'
                : name +
                  ' и другие товары представлены в ассортименте и по привлекательным ценам в интернет-магазине МегаСервис Орла',
            canonical:
                process.env.REACT_APP_BASIC_URL +
                RouteName.SHOP_PAGE +
                '/' +
                typeId
        };
    }
};

export interface BreadcrumbType {
    text: string;
    href?: string;
}

export const getProductBreadcrumb = (
    name: string,
    typeId: number,
    path: string
): BreadcrumbType[] => {
    if (path !== 'shop') {
        return [
            { text: 'Главная', href: RouteName.MAIN_PAGE },
            {
                text: 'Багетная мастерская',
                href: RouteName.FRAMING_WORKSHOP_PAGE
            },
            { text: name }
        ];
    } else {
        return [
            { text: 'Главная', href: RouteName.MAIN_PAGE },
            {
                text: isAllProduct(typeId) ? 'Магазин' : name
            }
        ];
    }
};

export const sortByName = (a: { name: string }, b: { name: string }): 1 | -1 =>
    a.name > b.name ? 1 : -1;
