import { useEffect, useMemo, useState } from 'react';

import { ThemeContext } from 'app/contexts';
import { Theme } from 'shared/model';
import { addThemeToHTML, getCurrentTheme } from 'shared/lib';

import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

export function ThemeProvider({ children }: Props) {
    const [theme, setTheme] = useState<Theme>(Theme.Light);

    useEffect(() => {
        const currentTheme = getCurrentTheme();
        addThemeToHTML(currentTheme);
        setTheme(currentTheme);
    }, []);

    const defaultValue = useMemo(
        () => ({
            theme,
            setTheme
        }),
        [theme]
    );

    return (
        <ThemeContext.Provider value={defaultValue}>
            {children}
        </ThemeContext.Provider>
    );
}
