import { memo, useState } from 'react';
import cn from 'classnames';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    label: string;
    children: ReactNode;
}

export const ToggleList = memo(({ label, children }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const buttonClass = cn('toggle-list__button', {
        open: open
    });
    return (
        <div className='toggle-list'>
            <button className={buttonClass} onClick={() => setOpen(!open)}>
                {label}
            </button>
            <div className={`toggle-list__menu ${open && 'open'}`}>
                {children}
            </div>
        </div>
    );
});
