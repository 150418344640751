import { authHost, host } from 'shared/model';
import { transformUser } from 'shared/lib';

import type { UserResponse } from 'shared/model';

const userUrl = '/users';

export const userApi = {
    async signUp(email: string, password: string): Promise<void> {
        await host.post<{ token: string }>(userUrl + '/registration', {
            email,
            password
        });
    },

    async signIn(email: string, password: string): Promise<UserResponse> {
        const { data } = await host.post<{ token: string }>(
            userUrl + '/login',
            { email, password }
        );
        return transformUser(data.token);
    },

    async authChecked(): Promise<UserResponse> {
        const { data } = await authHost.get<{ token: string }>(
            userUrl + '/auth'
        );
        return transformUser(data.token);
    },

    async checkoutOrder(info: FormData): Promise<void> {
        await authHost.post<{ status: string }>(userUrl + '/checkout', info);
    }
};
