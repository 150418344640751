import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { sorts } from 'shared/model';

import type { Sort } from 'shared/model';

export interface SortState {
    sort: Sort;
}

const initialState: SortState = {
    sort: sorts[0]
};

const sortSlice = createSlice({
    name: 'sort',
    initialState,
    reducers: {
        sortChanged(state, action: PayloadAction<Sort>) {
            state.sort = action.payload;
        }
    }
});

export const { sortChanged } = sortSlice.actions;

export const sortReducer = sortSlice.reducer;
