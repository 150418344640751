import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/lib';

import { filterChanged } from './filter-slice';

import type { Filter } from 'shared/model';

export const useFilter = () => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(state => state.filter.filter);

    const setFilter = useCallback(
        (filter: Filter[]): void => {
            dispatch(filterChanged(filter));
        },
        [dispatch]
    );

    return {
        filter,
        setFilter
    };
};
