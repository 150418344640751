import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { Type } from 'shared/model';

export const typesApi = createApi({
    baseQuery: fetchBaseQuery({
        method: 'GET',
        baseUrl: process.env.REACT_APP_API_URL
    }),
    reducerPath: 'typesApi',
    endpoints: build => ({
        getTypes: build.query<Type[], void>({
            query: () => ({
                url: '/types'
            })
        })
    })
});

export const { useGetTypesQuery } = typesApi;
