import { jwtDecode } from 'jwt-decode';

import type { UserResponse } from 'shared/model';

export const transformUser = (token: string): UserResponse => {
    const decodeData = jwtDecode(token) as UserResponse;
    return {
        id: decodeData.id,
        email: decodeData.email,
        token,
        role: decodeData.role,
        status: decodeData.status
    };
};
