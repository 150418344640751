import { memo } from 'react';
import cn from 'classnames';

import type { ReactNode } from 'react';

import './styles.scss';

interface Props {
    align?: 'center' | 'left' | 'right';
    children: ReactNode;
}

export const Title = memo(({ align = 'left', children }: Props) => {
    const textClass = cn('title', align);

    return <h1 className={textClass}>{children}</h1>;
});
