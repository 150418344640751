import { configureStore } from '@reduxjs/toolkit';

import { filterReducer } from 'entities/filter';
import { sortReducer } from 'entities/sort';
import { userReducer } from 'entities/user';
import {
    productsApi,
    typePropertyApi,
    typesApi,
    producerApi
} from 'shared/api';

import { signedUpMiddleware } from './middleware';

export const store = configureStore({
    reducer: {
        user: userReducer,
        sort: sortReducer,
        filter: filterReducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [typesApi.reducerPath]: typesApi.reducer,
        [producerApi.reducerPath]: producerApi.reducer,
        [typePropertyApi.reducerPath]: typePropertyApi.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            productsApi.middleware,
            typesApi.middleware,
            producerApi.middleware,
            typePropertyApi.middleware,
            signedUpMiddleware.middleware
        ])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
