import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/lib';

import { basketApi, localStorageApi, userApi } from 'shared/api';

import {
    userSignedUp,
    userSignedIn,
    userSignedOut,
    setBasket
} from './user-slice';

import type { BasketRequest } from 'shared/model';

export const useAuth = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.user.user);
    const basket = useAppSelector(state => state.user.basket);
    const authStatus = useAppSelector(state => state.user.authStatus);

    const signUp = useCallback(
        async (email: string, password: string): Promise<void> => {
            try {
                await userApi.signUp(email, password);
                dispatch(userSignedUp());
            } catch (e) {
                dispatch(userSignedOut());
            }
        },
        [dispatch]
    );

    const signIn = useCallback(
        async (email: string, password: string): Promise<void> => {
            try {
                const data = await userApi.signIn(email, password);
                const user = {
                    id: data.id,
                    email: data.email,
                    role: data.role || ''
                };
                localStorageApi.setToken(data.token);
                dispatch(userSignedIn(user));
            } catch (e) {
                dispatch(userSignedOut());
            }
        },
        [dispatch]
    );

    const signOut = useCallback(async (): Promise<void> => {
        try {
            localStorageApi.removeUserData();
        } finally {
            dispatch(userSignedOut());
        }
    }, [dispatch]);

    const authChecked = useCallback(async (): Promise<void> => {
        try {
            if (!localStorageApi.getToken()) {
                dispatch(userSignedOut());
                return;
            }
            const data = await userApi.authChecked();
            if (!data) {
                dispatch(userSignedOut());
                return;
            }
            const user = {
                id: data.id,
                email: data.email,
                role: data.role || ''
            };
            localStorageApi.setToken(data.token);
            dispatch(userSignedIn(user));
        } catch (e) {
            dispatch(userSignedOut());
        }
    }, [dispatch]);

    const checkoutOrder = useCallback(async (info: FormData): Promise<void> => {
        await userApi.checkoutOrder(info);
    }, []);

    const getBasket = useCallback(async (): Promise<void> => {
        const basket = await basketApi.getBasket();
        dispatch(setBasket(basket));
    }, [dispatch]);

    const createBasket = useCallback(
        async (basket: BasketRequest): Promise<void> => {
            await basketApi.createBasket(basket);
            await getBasket();
        },
        [getBasket]
    );

    const updateBasket = useCallback(
        async (productId: number, value: { value: number }): Promise<void> => {
            await basketApi.updateBasket(productId, value);
            await getBasket();
        },
        [getBasket]
    );

    const deleteBasket = useCallback(
        async (productId: number): Promise<void> => {
            await basketApi.deleteBasket(productId);
            await getBasket();
        },
        [getBasket]
    );

    const getValueByProductId = useCallback(
        async (productId: number): Promise<{ value: number }> =>
            await basketApi.getValueByProductId(productId),
        []
    );

    const getOrder = useCallback(
        async (): Promise<{ order: string }> => await basketApi.getOrder(),
        []
    );

    return {
        user,
        basket,
        authStatus,
        signUp,
        signIn,
        signOut,
        authChecked,
        checkoutOrder,
        createBasket,
        getBasket,
        updateBasket,
        deleteBasket,
        getValueByProductId,
        getOrder
    };
};
