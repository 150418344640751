import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/lib';

import { sortChanged } from './sort-slice';

import type { Sort } from 'shared/model';

export const useSort = () => {
    const dispatch = useAppDispatch();
    const sort = useAppSelector(state => state.sort.sort);

    const setSort = useCallback(
        async (sort: Sort): Promise<void> => {
            dispatch(sortChanged(sort));
        },
        [dispatch]
    );

    return {
        sort,
        setSort
    };
};
