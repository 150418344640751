import { memo } from 'react';

import { SVG } from '../../model';

import type { ImgHTMLAttributes } from 'react';
import type { SvgIconName } from '../../model';

export interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    icon: SvgIconName;
    width?: number;
    height?: number;
    widthAndHeight?: number;
}

export const Icon = memo(({ icon, width, height, widthAndHeight }: Props) => (
    <img
        src={SVG[icon]}
        width={widthAndHeight || width || 24}
        height={widthAndHeight || height || 24}
        alt='Иконка'
    />
));
