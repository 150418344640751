import { lazy } from 'react';

import { RouteName } from 'shared/model';

import type { RouteDescription } from 'shared/model';

const Main = lazy(() => import('pages/main'));
const Registration = lazy(() => import('pages/registration'));
const Login = lazy(() => import('pages/login'));
const Shop = lazy(() => import('pages/shop'));
const Product = lazy(() => import('pages/product'));
const Services = lazy(() => import('pages/services'));
const Contacts = lazy(() => import('pages/contacts'));
const FramingWorkshop = lazy(() => import('pages/framing-workshop'));
const FramingWorkshopGallery = lazy(
    () => import('pages/framing-workshop-gallery')
);
const DeliveryPayment = lazy(() => import('pages/delivery-payment'));
const Jobs = lazy(() => import('pages/jobs'));
const News = lazy(() => import('pages/news'));
const Admin = lazy(() => import('pages/admin'));
const Basket = lazy(() => import('pages/basket'));

export const publicRoutes: RouteDescription[] = [
    {
        path: RouteName.MAIN_PAGE,
        component: Main
    },
    {
        path: RouteName.REGISTRATION_PAGE,
        component: Registration
    },
    {
        path: RouteName.LOGIN_PAGE,
        component: Login
    },
    {
        path: RouteName.SHOP_PAGE + '/:id',
        component: Shop
    },
    {
        path: RouteName.PRODUCT_PAGE + '/:id',
        component: Product
    },
    {
        path: RouteName.SERVICES_PAGE + '/:id',
        component: Services
    },
    {
        path: RouteName.CONTACTS_PAGE,
        component: Contacts
    },
    {
        path: RouteName.FRAMING_WORKSHOP_PAGE,
        component: FramingWorkshop
    },
    {
        path: RouteName.FRAMING_WORKSHOP_PAGE + '/:id',
        component: FramingWorkshopGallery
    },
    {
        path: RouteName.DELIVERY_PAYMENT_PAGE,
        component: DeliveryPayment
    },
    {
        path: RouteName.JOBS_PAGE,
        component: Jobs
    },
    {
        path: RouteName.NEWS_PAGE,
        component: News
    }
];

export const authRoutes: RouteDescription[] = [
    {
        path: RouteName.ADMIN_PAGE,
        component: Admin
    },
    {
        path: RouteName.BASKET_PAGE,
        component: Basket
    }
];
