import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { AuthStatus, type BasketItem, type User } from 'shared/model';

export interface UserState {
    user: User;
    basket: BasketItem[];
    authStatus: AuthStatus;
}

const initialState: UserState = {
    user: {
        id: null,
        email: '',
        role: ''
    },
    basket: [],
    authStatus: AuthStatus.Loading
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userSignedUp(state) {
            state.authStatus = AuthStatus.SignedUp;
        },
        userSignedIn(state, action: PayloadAction<User>) {
            state.user = action.payload;
            state.authStatus = AuthStatus.SignedIn;
        },
        userSignedOut(state) {
            state.user = initialState.user;
            state.authStatus = AuthStatus.SignedOut;
            state.basket = [];
        },
        setBasket(state, action: PayloadAction<BasketItem[]>) {
            state.basket = action.payload;
        }
    }
});

export const { userSignedUp, userSignedIn, userSignedOut, setBasket } =
    userSlice.actions;

export const userReducer = userSlice.reducer;
