import { host } from 'shared/model';

import type {
    Producer,
    ProductFull,
    ProductResponse,
    Property,
    Type,
    TypePropertyFull,
    ValueFull
} from 'shared/model';

export const adminApi = {
    async getProducts(): Promise<ProductFull[]> {
        const { data } = await host.get<ProductFull[]>('/products/admin');
        return data;
    },

    async getPropertyByTypeId(typeId: number): Promise<TypePropertyFull[]> {
        const { data } = await host.get<TypePropertyFull[]>(
            'type_properties?typeId=' + typeId
        );
        return data;
    },

    async getType(): Promise<Type[]> {
        const { data } = await host.get<Type[]>('/types');
        return data;
    },

    async getProducer(): Promise<Producer[]> {
        const { data } = await host.get<Producer[]>('/producers');
        return data;
    },

    async getProperties(): Promise<Property[]> {
        const { data } = await host.get<Property[]>('/properties');
        return data;
    },

    async getTypeProperty(): Promise<TypePropertyFull[]> {
        const { data } = await host.get<TypePropertyFull[]>('type_properties');
        return data;
    },

    async getValue(): Promise<ValueFull[]> {
        const { data } = await host.get<ValueFull[]>('/values');
        return data;
    },

    async createProduct(product: FormData): Promise<void> {
        await host.post<ProductResponse>('/products', product);
    },

    async updateProduct(id: number, products: FormData): Promise<void> {
        await host.put<ProductResponse>('/products/' + id, products);
    },

    async deleteProduct(id: number): Promise<void> {
        await host.delete<ProductResponse>('/products/' + id);
    },

    async createType(products: FormData): Promise<void> {
        await host.post<ProductResponse>('/types', products);
    },

    async deleteType(id: number): Promise<void> {
        await host.delete<ProductResponse>('/types/' + id);
    },

    async createProducer(products: FormData): Promise<void> {
        await host.post<ProductResponse>('/producers', products);
    },

    async deleteProducer(id: number): Promise<void> {
        await host.delete<ProductResponse>('/producers/' + id);
    },

    async createProperty(property: FormData): Promise<void> {
        await host.post<ProductResponse>('/properties', property);
    },

    async deleteProperty(id: number): Promise<void> {
        await host.delete<ProductResponse>('/properties/' + id);
    },

    async createTypeProperty(typeProperty: FormData): Promise<void> {
        await host.post<ProductResponse>('/type_properties', typeProperty);
    },

    async deleteTypeProperty(id: number): Promise<void> {
        await host.delete<ProductResponse>('/type_properties/' + id);
    },

    async createValue(value: FormData): Promise<void> {
        await host.post<ProductResponse>('/values', value);
    },

    async deleteValue(id: number): Promise<void> {
        await host.delete<ProductResponse>('/values/' + id);
    }
};
