import { authHost } from 'shared/model';

import type { BasketItem, BasketRequest, BasketResponse } from 'shared/model';

const basketUrl = '/baskets';

export const basketApi = {
    async createBasket(basket: BasketRequest): Promise<void> {
        await authHost.post<BasketResponse>(basketUrl, basket);
    },

    async getBasket(): Promise<BasketItem[]> {
        const { data } = await authHost.get<BasketItem[]>(basketUrl);
        return data;
    },

    async getValueByProductId(productId: number): Promise<{ value: number }> {
        const { data } = await authHost.get<{ value: number }>(
            basketUrl + productId
        );
        return data;
    },

    async getOrder(): Promise<{ order: string }> {
        const { data } = await authHost.get<{ order: string }>(
            basketUrl + '/order'
        );
        return data;
    },

    async updateBasket(
        productId: number,
        value: { value: number }
    ): Promise<void> {
        await authHost.put<BasketResponse>(basketUrl + '/' + productId, value);
    },

    async deleteBasket(productId: number): Promise<void> {
        await authHost.delete<BasketResponse>(basketUrl + '/' + productId);
    }
};
