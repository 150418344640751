import { memo } from 'react';
import cn from 'classnames';

import type { InputHTMLAttributes } from 'react';

import './styles.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
    label?: string;
    variant?: 'primary' | 'secondary';
    size?: 'medium' | 'large';
    width?: 'auto' | 'max';
    borderRadius?: 'primary' | 'secondary';
    isError?: boolean;
    error?: string;
}

export const Input = memo(
    ({
        label,
        variant = 'primary',
        size = 'medium',
        width = 'max',
        borderRadius = 'primary',
        isError,
        error,
        type,
        value,
        disabled,
        children,
        ...props
    }: Props) => {
        const fieldClass = cn('field', variant, size, width, {
            error: error || isError
        });
        const inputContainerClass = cn(
            'input',
            variant,
            `${borderRadius}-border-radius`,
            size,
            width,
            {
                error: error || isError,
                disabled: disabled
            }
        );
        const inputClass = cn(variant, {
            'with-label': size != 'medium' && value,
            error: error
        });
        return (
            <div className={fieldClass}>
                <div className={inputContainerClass}>
                    {size != 'medium' && value && (
                        <div className='label'>{label}</div>
                    )}
                    <input
                        value={value}
                        type={type || 'text'}
                        placeholder={label || ''}
                        disabled={disabled}
                        className={inputClass}
                        {...props}
                    />
                    {children && <div className='input-icon'>{children}</div>}
                </div>
                {error && <div className='field__error'>{error}</div>}
            </div>
        );
    }
);
