export enum Delivery {
    Pickup = 'Самовывоз',
    Delivery = 'Доставка по Орлу'
}

export const deliveryInfo = {
    title: 'Доставка',
    text: 'По вопросам доставки вы также можете связаться с нашим менеджером по e-mail: info@mega-orel.ru',
    info: [
        {
            label: Delivery.Pickup,
            text: [
                'Для жителей города Орла есть возможность забрать заказанный товар по адресу: г. Орел, ул. Комсомольская, д.231, Дом Моды, центральный вход, 1 этаж'
            ]
        },
        {
            label: Delivery.Delivery,
            text: [
                'Доставка осуществляется в будние дни',
                'День и время доставки необходимо согласовать с нашим менеджером',
                'Стоимость доставки товара - 250 ₽ ( весом до 5 кг )'
            ]
        }
    ]
};
